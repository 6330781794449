import axios from 'axios';
import { toast } from 'react-toastify';

import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '@ohif/viewer/src/utils/localStorageKeys';

// this base url will be change based on
// if you need to point to production.
const debugMode = process.env.NODE_ENV !== 'production';
const BASE_URL = debugMode ? 'http://127.0.0.1:8000' : '';

// This request is used when we do NOT have login credentials
// e.g. password reset, user signup
let tokenRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

// Access token is refreshed frequently, and used for every auth request
const setAccessToken = accessToken => {
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);
};

// Refresh token is only issued at time of user login.
const setRefreshToken = refreshToken => {
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

const SignupUser = async (username, email, password1, password2) => {
  const signupBody = {
    username: username,
    email: email,
    password1: password1,
    password2: password2,
  };
  try {
    const response = await tokenRequest.post(
      `/api/dj-rest-auth/registration/`,
      signupBody
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const ResetPassword = async email => {
  const resetBody = {
    email: email,
  };
  try {
    const response = await tokenRequest.post(
      `/api/dj-rest-auth/password/reset/`,
      resetBody
    );
    return await Promise.resolve(response.data);
  } catch (error) {
    return await Promise.reject(error);
  }
};

const ResendEmailAPI = async email => {
  const resetBody = {
    email: email,
  };
  try {
    const response = await tokenRequest.post(
      `/api/dj-rest-auth/registration/resend-email/`,
      resetBody
    );
    return await Promise.resolve(response.data);
  } catch (error) {
    return await Promise.reject(error);
  }
};

const ResetPasswordConfirm = async (
  new_password1,
  new_password2,
  uid,
  token
) => {
  const resetBody = {
    new_password1: new_password1,
    new_password2: new_password2,
    uid: uid,
    token: token,
  };
  try {
    const response = await tokenRequest.post(
      `/api/dj-rest-auth/password/reset/confirm/`,
      resetBody
    );
    return await Promise.resolve(response.data);
  } catch (error) {
    return await Promise.reject(error);
  }
};


// This is a reusable axios request object that requires a logged-in user with a valid JWT access token.
// The access token is retrieved from browser localStorage each time this is used.
const authRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Ensure that the access token is attached to the headers
authRequest.interceptors.request.use(config => {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});


// Updated this error interceptor
// This interceptor is for any error in axios authRequest
authRequest.interceptors.response.use(
  (response) => response, // this is for all successful requests.
  async (error) => {
    if (error.response) {
      // Error has a response. Get status and data, if any.
      const errStatus = error.response.status;
      const errData = error.response.data;
      // Handle different status codes
      if (errStatus === 400) {
        // Bad request. If there is a detail message, display to user
        toast.error(
          'リクエストに問題があります。' + (errData['detail'] || '')
        );
      }
      else if (errStatus === 401) {

        // Check if a refresh token exists
        const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
        // Logout immediately under some conditions
        if (!refreshToken) {
          // No refresh token at all, user must be logged out
          console.warn('No refresh token, logout');
          await logoutUser();
        }
        if (errData['detail'] === 'User not found') {
          // User account does not exit
          console.warn('User not found, logout');
          await logoutUser();
        }
        if (errData['code'] === 'user_inactive') {
          // User signed up, but account is inactive
          // Before logout, get email for redirect
          let email = window.localStorage.getItem('email');
          if (email === null || email === undefined){
            email = '';
          }
          console.log('User inactive, logout');
          await logoutUser();
          location.href = `/conference/welcome/${email}`;
        }
        // End conditions that require immediate logout

        try {
          // Here we assume that user is logged in, but access token expired
          // Try to refresh the access token, update header
          // https://medium.com/@sanchit0496/what-is-axios-interceptor-how-to-handle-refresh-tokens-in-frontend-7e8bbdbb8ac9
          console.log('Get new access token...');
          await getNewAccessToken();
          const newToken = window.localStorage.getItem(ACCESS_TOKEN);
          if (newToken) {
            console.debug('Got new token:', newToken);
            // Update default header for axios requests
            const headerAuth = `Bearer ${newToken}`;
            authRequest.defaults.headers['Authorization'] = headerAuth;
            axios.defaults.headers.common['Authorization'] = headerAuth;

            // Clone original request, and retry
            const originalRequest = error.config
            console.log('Retry request', originalRequest.url);
            originalRequest.headers['Authorization'] = headerAuth;
            return authRequest(originalRequest);
          } else {
            console.warn('No access token!', newToken);
          }

        } catch (accessTokenError) {
          console.warn('Error refreshing access token:', accessTokenError);
          await logoutUser();
          return Promise.reject(accessTokenError);
        }
      }  // End of handling status 401

      // Handle other status codes
      else if (errStatus === 403) {
        // TODO: Handle permission denied
      } else if (errStatus === 404) {
        toast.error('データが存在しません');
      } else if (errStatus === 500) {
        toast.error('サーバーでエラーが発生しています');
      } else {
        console.error('Unhandled error status:', errStatus, error);
        toast.error('サーバーでエラーが発生しました: ' + errStatus);
      }
    } else {
      console.error('Unhandled error:', error);
      // await logoutUser();
      return Promise.reject(error);
    }
  }
);

const loginUser = async (email, password) => {
  const loginBody = { email: email, password: password };
  try {
    const response = await tokenRequest.post(
      `/api/dj-rest-auth/login/`,
      loginBody
    );
    // window.localStorage.setItem(ACCESS_TOKEN, response.data.access_token);
    // window.localStorage.setItem(REFRESH_TOKEN, response.data.refresh_token);
    setAccessToken(response.data.access_token);
    setRefreshToken(response.data.refresh_token);
    console.log(
      'User LOGGED IN by email:',
      response.data.user.email,
      response.data.user.username
    );
    window.localStorage.setItem('email', response.data.user.email);
    window.localStorage.setItem('username', response.data.user.username);
    return await Promise.resolve(response);
  } catch (error) {
    return await Promise.reject(error);
  }
};

const loginUserGoogle = async accessToken => {
  const loginBody = { access_token: accessToken };
  try {
    const response = await tokenRequest.post(
      `/api/dj-rest-auth/google/`,
      loginBody
    );
    setAccessToken(response.data.access_token);
    setRefreshToken(response.data.refresh_token);
    console.log(
      'Use LOGGED IN by Google Oauth:',
      response.data.user.email,
      response.data.user.username
    );
    window.localStorage.setItem('email', response.data.user.email);
    window.localStorage.setItem('username', response.data.user.username);
    return await Promise.resolve(response);
  } catch (error) {
    return await Promise.reject(error);
  }
};


// Changed the name of this function. `refreshToken` was very confusing.
// We are getting a new access token, using the long-lived JWT Refresh Token.
// `refreshToken` is used elsewhere for the value of JWT Refresh Token.
const getNewAccessToken = async () => {
  const refreshBody = { refresh: window.localStorage.getItem(REFRESH_TOKEN) };
  const response = await tokenRequest.post(
    `/api/dj-rest-auth/token/refresh/`,
    refreshBody
  );
  console.debug('refreshToken OK', response);
  if (response.data && response.data.access) {
    setAccessToken(`${response.data.access}`);
  } else {
    console.error('getNewAccessToken: no token in response', response);
  }
  return await Promise.resolve(response.data);
};

const getUserInfo = async () => {
  let userInfo;
  try {
    const response = await authRequest.get('/api/dj-rest-auth/user/');
    userInfo = response.data;
  } catch {
    console.warn('Cannot get user info, login expired?');
    userInfo = {};
  }
  console.debug('userInfo', userInfo);
  return userInfo;
};

const userLoggedIn = async () => {
  const userInfo = await getUserInfo();
  return userInfo && Object.keys(userInfo).length > 0;
};

const logoutUser = async () => {
  // removeOrgLastUrl();
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  window.localStorage.removeItem('email');
  window.localStorage.removeItem('username');
  // This does not work across tabs!
  authRequest.defaults.headers['Authorization'] = '';
};

export {
  tokenRequest,
  getUserInfo,
  userLoggedIn,
  loginUser,
  loginUserGoogle,
  logoutUser,
  SignupUser,
  ResetPassword,
  ResetPasswordConfirm,
  ResendEmailAPI,
  getNewAccessToken,
  authRequest,
  // errorInterceptor,
  BASE_URL,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
};
